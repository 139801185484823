import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import Button from 'reusecore/src/elements/Button';
import './style.css';

export default class ContactForm extends React.Component {
   state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      isSent: false,
      showSendError: false,
   };

   handleEmailChange = event => {
      const email = event.target.value;
      this.setState({ email, isSent: false, showSendError: false });
   };

   handleNameChange = event => {
      const name = event.target.value;
      this.setState({ name, isSent: false, showSendError: false });
   };

   handleSubjectChange = event => {
      const subject = event.target.value;
      this.setState({ subject, isSent: false, showSendError: false });
   };

   handleMessageChange = event => {
      const message = event.target.value;
      this.setState({ message, isSent: false, showSendError: false });
   };

   handleSubmit = e => {
      const { email } = this.state;

      if (email.endsWith("gemail.ru")) {
         // Fucking spammers!
         this.setState({
            email: '',
            name: '',
            subject: '',
            message: '',
            isSent: true,
            showSendError: false,
         });
      } else {
         fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: this.encode({'form-name': 'contact', ...this.state}),
         })
         .then(() => {
            this.setState({
               email: '',
               name: '',
               subject: '',
               message: '',
               isSent: true,
               showSendError: false,
            });
         })
         .catch(() => {
            this.setState({
               email: '',
               name: '',
               subject: '',
               message: '',
               isSent: false,
               showSendError: true,
            });
         });

         e.preventDefault();
      }
   };

   encode = data => {
      return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
   };

   render() {
      const { name, email, subject, message, isSent, showSendError } = this.state;

      const messageSent = isSent ? (
      <p>Your message has been sent. We'll respond as soon as we can!</p>
      ) : null;

      const sendErrorMessage = showSendError ? (
      <p>
         An error occurred while attempting to send your message. Please contact
         us at{' '}
         <a href="mailto:support@printablecal.com">support@printablecal.com</a>{' '}
         while we work on determining what went wrong.
      </p>
      ) : null;

      return (
      <ValidatorForm
      ref="form"
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
      onError={errors => console.log(errors)}
      >
         <input type="hidden" name="form-name" value="contact" />
         <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
               <TextValidator
               label="Name"
               aria-label="Name"
               onChange={this.handleNameChange}
               name="name"
               value={name}
               validators={['required']}
               style={{ width: '100%'}}
               variant="filled"
               errorMessages={['Please enter your name.']}
               />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
               <TextValidator
               label="Email address"
               aria-label="Email address"
               onChange={this.handleEmailChange}
               name="email"
               value={email}
               validators={['required', 'isEmail']}
               style={{ width: '100%' }}
               variant="filled"
               errorMessages={[
                  'Please enter your email address so we can provide a response.',
                  'Please enter a valid email address.',
               ]}
               />
            </Grid>
            <Grid item xs={12}>
               <TextValidator
               label="Subject"
               aria-label="Subject"
               onChange={this.handleSubjectChange}
               name="subject"
               value={subject}
               validators={['required']}
               style={{ width: '100%' }}
               variant="filled"
               errorMessages={['Please enter a subject.']}
               />
            </Grid>
            <Grid item xs={12}>
               <TextValidator
               label="Message"
               aria-label="Message"
               onChange={this.handleMessageChange}
               name="message"
               value={message}
               validators={['required']}
               style={{ width: '100%' }}
               variant="filled"
               multiline
               rows="10"
               errorMessages={[
                  'Please enter the message you would like to send.',
               ]}
               />
            </Grid>
            <Grid item xs={12}>
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                  size="large"
                  type="submit"
                  title="Send Message"
                  style={{
                     fontSize: 24,
                     fontWeight: 400,
                     height: 64,
                     borderRadius: 32,
                     padding: '8px 22px 8px 22px',
                  }}
                  />
               </div>
            </Grid>
         </Grid>
         <div style={{ textAlign: 'center' }}>
            {messageSent}
            {sendErrorMessage}
         </div>
      </ValidatorForm>
      );
   }
}
