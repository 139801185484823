import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import { DrawerContext } from '../../contexts/DrawerContext';

const resolveLink = (menuItem, drawerClose, toggleDrawer) => {
  if (menuItem.path.startsWith("#")) {
    return drawerClose ? (
       <Link to={`/${menuItem.path}`} onClick={toggleDrawer}>
         {menuItem.label}
       </Link>
    ) : (
       <Link to={`/${menuItem.path}`}>{menuItem.label}</Link>
    )
  }
  else if (menuItem.path.startsWith("http")) {
    return (<a href={menuItem.path} target="_blank"  rel="noopener noreferrer">{menuItem.label}</a>);
  }

  return (<Link to={`/${menuItem.path}`}>{menuItem.label}</Link>);
};

const resolveAnchorLink = (menuItem, drawerClose, toggleDrawer, overrideCurrent) => {
  if (menuItem.path.startsWith("#")) {
    return (
      <>
        {drawerClose ? (
           <AnchorLink
              href={menuItem.path}
              offset={menuItem.offset}
              onClick={toggleDrawer}
              style={
                overrideCurrent === menuItem.label
                   ? { color: '#007C3E' }
                   : null
              }
           >
             {menuItem.label}
           </AnchorLink>
        ) : (
           <AnchorLink href={menuItem.path} offset={menuItem.offset}>
             {menuItem.label}
           </AnchorLink>
        )}
      </>
    );
  }
  else if (menuItem.path.startsWith("http")) {
    return (<a href={menuItem.path} target="_blank"  rel="noopener noreferrer">{menuItem.label}</a>);
  }

  return (<Link to={menuItem.path}>{menuItem.label}</Link>);
};

const ScrollSpyMenu = ({
  className,
  menuItems,
  drawerClose,
  fromChildPage,
  overrideCurrent,
  ...props
}) => {
  const { dispatch } = useContext(DrawerContext);

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  if (fromChildPage) {
    return (
      <div className={addAllClasses.join(' ')}>
        {menuItems.map((menu, index) => (
          <li
            className={overrideCurrent === menu.label ? 'is-current' : null}
            key={`menu-item-${index}`}
          >
            {resolveLink(menu, drawerClose, toggleDrawer)}
          </li>
        ))}
      </div>
    );
  } else {
    // empty array for scrollspy items
    const scrollItems = [];

    // convert menu path to scrollspy items
    menuItems.forEach(item => {
      scrollItems.push(item.path.slice(1));
    });

    return (
      <Scrollspy
        items={scrollItems}
        className={addAllClasses.join(' ')}
        drawerClose={drawerClose}
        {...props}
      >
        {menuItems.map((menu, index) => (
          <li
            className={overrideCurrent === menu.label ? 'is-current' : null}
            key={`menu-item-${index}`}
          >
            {resolveAnchorLink(menu, drawerClose, toggleDrawer, overrideCurrent)}
          </li>
        ))}
      </Scrollspy>
    );
  }
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,

  fromChildPage: PropTypes.bool,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
