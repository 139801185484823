import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

function SEO({description, lang, keywords, title}) {
	const metaTitle = `PrintableCal | ${title}`;
	return (
		<Helmet htmlAttributes={{lang}}>
			<title>{metaTitle}</title>
			<meta name="description" content={description}/>
			<meta name="keywords" content={keywords}/>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: null,
   isSearchable: false
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	keywords: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
};

export default SEO;
