import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import {Link} from 'gatsby';
import GetAppIcon from '@material-ui/icons/GetApp';

const LargeDownloadButton = ({
	                             buttonWrapper,
	                             fillButton,
	                             requirementsColor,
	                             showRequirements
                             }) => {
	return (
		<>
			<Box {...buttonWrapper}>
				<Link to="/Download" style={{textDecoration: 'none'}}>
					<Button
						{...fillButton}
						icon={<GetAppIcon style={{height:40, width: 40, marginTop: 4}}/>}
						iconPosition="left"
						isMaterial={true}
						title="Download Now"
						style={{
							borderRadius: 40,
							color: '#000',
						}}
					/>
				</Link>
			</Box>
         {showRequirements ? (
            <p
               style={{
                  textAlign: 'center',
                  fontSize: 14,
                  lineHeight: 1.6,
                  color: requirementsColor,
                  marginBottom: 0,
               }}
            >
               Runs on Windows 10, 8, and 7. Requires Excel, Word, or PowerPoint
               (Office 365, Office 2007 or later).
               <br/>
               Compatible with both 32-bit and 64-bit versions of Office.
            </p>
            ) : null}
		</>
	);
};

LargeDownloadButton.propTypes = {
	buttonWrapper: PropTypes.object,
	fillButton: PropTypes.object,
};

LargeDownloadButton.defaultProps = {
	showRequirements: true,
	fillButton: {
		type: 'button',
		color: '#fff',
		fontSize: '32px',
		fontWeight: '600',
		p: ['0px 15px', '8px 32px'],
		colors: 'secondaryWithBg',
		minWidth: ['auto', '150px'],
		height: '80px',
		minHeight: 'auto',
	},
	buttonWrapper: {
		flexBox: true,
		justifyContent: 'center',
		mt: '35px',
	},
};

export default LargeDownloadButton;
