import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import ribbon_create_calendar from '../../../../../common/src/assets/image/ribbon_create_calendar.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can create calendars in Microsoft Excel, Word, and
        PowerPoint using a variety of templates and data sources. To create a
        calendar, click the <strong>Create Calendar</strong> button on the
        PrintableCal tab of the ribbon.
      </p>
      <p>
        <Image alt="" src={ribbon_create_calendar} style={{ width: 854 }} />
      </p>
      <p>
        After clicking the <strong>Create Calendar</strong> button, the Task
        Pane will guide you through the steps. When completed with a step, click
        the <strong>Next</strong> button to move to the next step. PrintableCal
        will remember all previous settings, so if you want to quickly generate
        a calendar using previous settings, simply click <strong>Next</strong>{' '}
        on each step.{' '}
      </p>
      <p>Click a step to learn more about it...</p>
      <ol>
        <li>
          <Link to="/Documentation/Create-Calendar/Choose-a-Template">
            Choose a Template
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Templates-and-Options">
            Templates and Options
          </Link>
        </li>
        <li>
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>
        </li>
      </ol>
    </div>
  );
  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar"
      commentsId="commentsplus_post_90_489"
      title="Create Calendar"
      description="PrintableCal can create calendars in Microsoft Excel and Word using a variety of templates and data sources, such as Outlook, Google Calendar, and Yahoo! Calendar."
      keywords="create printable calendar, generate calendar, calendar template, printable template, Excel calendar, Word calendar, 2020 calendar, create calendar, generate calendar, printable holidays"
      content={content}
    />
  );
};

export default Documentation;
