import React, { Fragment } from 'react';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import SEO from '../../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from './Navbar';
import CommentsSection from '../Comments';
import Footer from '../Footer';
import PropTypes from 'prop-types';
import MenuBar from './MenuBar';
import DocumentationWrapper from './documentation.style';
import Box from 'reusecore/src/elements/Box';
import Contents from './Contents';
import LargeDownloadButton from 'common/src/components/LargeDownloadButton';
import ContactForm from 'common/src/components/ContactForm';
import Layout from '../../../components/layout';

const DocumentationSection = ({
  sectionWrapper,
  secHeading,
  secContent,
  url,
  commentsId,
  title,
  description,
  keywords,
  content,
  showDownload,
  showContact,
}) => {
  return (
    <Layout>
      <Fragment>
        <SEO title={title} description={description} keywords={keywords} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MenuBar />
          <Box {...sectionWrapper} as="section" id="documentation_section">
            <Container>
              <DocumentationWrapper>
                <Contents />
                <div className="DocSearch-content">
                  <Heading {...secHeading} as={'h1'} content={title} />
                  <div {...secContent}>{content}</div>
                </div>
                {showContact ? (
                  <>
                    <Heading
                      {...secHeading}
                      as={'h2'}
                      content="Have questions about PrintableCal?"
                    />
                    <div {...secContent}>
                      <p>
                        If this documentation doesn't answer your questions,
                        please contact us. We'd be happy to help! You can send
                        your question by entering it below. We'll respond as
                        soon as we can, and always within 1 business day.
                      </p>
                      <ContactForm />
                    </div>
                  </>
                ) : null}
                {showDownload ? (
                  <>
                    <Heading
                      {...secHeading}
                      as={'h2'}
                      content="Don't have PrintableCal yet?"
                    />
                    <div {...secContent}>
                      <p>
                        Installation of PrintableCal is quick and easy. All you
                        need is a Windows computer with Excel, Word, or
                        PowerPoint. Click the button below to start the
                        download. You don't need to sign up for anything or
                        provide an email address - simply download and run the
                        installer. You'll be creating printable calendars in no
                        time.
                      </p>
                    </div>
                    <LargeDownloadButton />
                  </>
                ) : null}
              </DocumentationWrapper>
            </Container>
            <div style={{ background: '#f7f7f7', marginTop: 64 }}>
              <Container>
                <CommentsSection
                  showHeading={false}
                  url={url}
                  identifier={commentsId}
                  title={title}
                />
              </Container>
            </div>
            <Footer />
          </Box>
        </ContentWrapper>
      </Fragment>
    </Layout>
  );
};

DocumentationSection.propTypes = {
  secHeading: PropTypes.object,
  secContent: PropTypes.object,
};

DocumentationSection.defaultProps = {
  showDownload: true,
  showContact: true,
  sectionWrapper: {
    pt: 0,
    pb: 0,
    ml: [0, 0, 0, '300px', '300px', 'auto'],
  },
  secHeading: {
    textAlign: 'left',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  secContent: {
    mb: 48,
  },
};

export default DocumentationSection;
