import React from 'react';
import Disqus from 'disqus-react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';

const CommentsSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  showHeading,
  url,
  identifier,
  title,
  heading,
}) => {
  const disqusShortname = 'printablecal';
  const disqusConfig = {
    url: url,
    identifier: identifier,
    title: title,
  };

  return (
    <Box {...sectionWrapper} as="section" id="comments_section">
      <Container>
        {showHeading ? (
          <Box {...secTitleWrapper}>
            <div>
              <Text {...secText} content="COMMENTS" />
              <Heading {...secHeading} content={heading} />
            </div>
          </Box>
        ) : (
          <div style={{ height: 48 }} />
        )}
        <Disqus.DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
        />
      </Container>
    </Box>
  );
};

CommentsSection.propTypes = {
  sectionHeader: PropTypes.object,
};

CommentsSection.defaultProps = {
  sectionWrapper: {
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    pt: 60,
    mb: 20,
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#007C3E',
    mb: '5px',
  },
  secHeading: {
    fontSize: ['32px', '32px', '40px', '42px', '46px'],
    fontFamily: 'Google Sans',
    fontWeight: '400',
    letterSpacing: '-0.5px',
    lineHeight: '1.17857',
    textAlign: 'center',
    color: '#0f2137',
    mb: '0',
  },
};

export default CommentsSection;
