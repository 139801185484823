import React, { Component } from 'react';
import { Link } from 'gatsby';
import menuItems from '../menuItems';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import withSizes from 'react-sizes';

class Contents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount() {
    this.setState({ ready: true });
  }

  render() {
    const { hideDrawer } = this.props;
    const { ready } = this.state;

    if (ready && hideDrawer) {
      const items = menuItems.items.map(menuItem => {
        return (
          <li key={menuItem.name}>
            <Link to={menuItem.url}>{menuItem.name}</Link>
          </li>
        );
      });

      return (
        <ExpansionPanel style={{ background: '#eee' }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3 style={{ margin: 0, padding: 0 }}>See Also</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul>{items}</ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }

    return null;
  }
}

const mapSizesToProps = ({ width }) => ({
  hideDrawer: width < 991,
});

export default withSizes(mapSizesToProps)(Contents);
