import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';
import LazyLoad from 'react-lazyload';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },
  base,
  themed('Image')
);

const Image = ({ src, alt, lazy, ...props }) => {
  if (lazy) {
    return (
      <LazyLoad height={200} offset={100} once>
        <ImageWrapper src={src} alt={alt} {...props} />
      </LazyLoad>
    );
  }

  return <ImageWrapper src={src} alt={alt} {...props} />;
};

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  m: 0,
  lazy: true
};
