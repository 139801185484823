import styled from 'styled-components';

const FooterWrapper = styled.footer`
  position: relative;
  background: #ddd;
  overflow: hidden;
  background: #eee;

  .footer_container {
    padding-top: 80px;
    padding-bottom: 40px;
    position: relative;
  }

  a {
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: underline;
    }
  }

  .releaseNotesCol li {
    color: rgba(52, 61, 72, 0.8);
    font-size: 14px;
    list-style-type: inherit;
    margin-bottom: 4px;
    margin-left: 24px;
  }

  .aboutLink {
    padding: 3px 0;
    font-size: 14px;
  }
`;

const List = styled.ul``;

export { List };

export default FooterWrapper;
