import styled from 'styled-components';

const DocumentationWrapper = styled.div`
   {
    margin-top: 100px;
    line-height: 1.6;
  }

  p {
    padding-left: 2px;
  }

  ol {
    padding-left: 18px;
  }

  li {
    list-style-type: inherit;
    margin-bottom: 4px;
    margin-left: 24px;
  }

  img {
    margin: 12px 0 24px 0;
  }

  a {
    text-decoration: underline;
  }

  h1,
  h2 {
    text-align: left;
    font-size: 28px;
    font-weight: 500;
    color: #0f2137;
    letter-spacing: -0.025em;
    margin-top: 40px;
    margin-bottom: 0;
    line-height: 1.67;
  }

  h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 500;
    color: #0f2137;
    letter-spacing: -0.025em;
    margin-top: 40px;
    margin-bottom: 8px;
    line-height: 1.67;
  }

  p {
    margin-bottom: 28px;
  }

  .sectioned h2 {
    margin-top: 120px;
  }

  .numbered li {
    margin-bottom: 28px;
  }

  .table {
    border-spacing: 0;
  }

  .table th,
  .table td {
    padding: 12px 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dddddd;
  }
`;

export default DocumentationWrapper;
