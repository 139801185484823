import React, { Component } from 'react';
import withSizes from 'react-sizes';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'gatsby';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import scrollIntoView from 'scroll-into-view-if-needed';
import menuItems from '../menuItems';

const styles = {
  list: {
    width: 300,
    marginTop: 76,
    background: '#dddddd',
    height: 'calc(100vh - 76px)',
    float: 'left'
  },
  itemContainer: {
    padding: '8px 16px',
  },
  links: {
    textDecoration: 'none',
    color: '#0f2137',
  },
};

class MenuBar extends Component {
  activeId = null;

  constructor(props) {
    super(props);
    this.state = {
      expansionState: {},
    };
  }

  // This method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
  handleClick(item) {
    this.setState(prevState => ({
      [item]: !prevState[item],
    }));
  }

  componentDidMount() {
    if (this.activeId) {
      const activeElement = document.getElementById(this.activeId);

      if (activeElement) {
        scrollIntoView(activeElement, {
          scrollMode: 'if-needed',
          inline: 'nearest',
        });
      }
    }
  }

  // If the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any
  // location and if there is no child this method uses recursion to go until the last level of children and then
  // returns the item by the first condition.
  handler(children, level) {
    const { classes } = this.props;
    const { state } = this;
    const paddingLeft = level * 20;

    return children.map(subOption => {
      let backgroundColor;
      let isActive = false;
      let pathname = document.location.pathname;

      if (pathname.endsWith('/')) {
        pathname = pathname.substr(0, pathname.length - 1);
      }

      const textColor = subOption.url === pathname ? '#fff' : '#0f2137';

      if (pathname.indexOf(subOption.url) >= 0) {
        if (pathname === subOption.url) {
          backgroundColor = '#217346';
          isActive = true;
          this.activeId = subOption.name;
        } else if (subOption.children) {
          isActive = true;
        }
      }

      if (!backgroundColor) {
        switch (level) {
          case 0:
            backgroundColor = '#DDDDDD';
            break;
          case 1:
            backgroundColor = '#EEEEEE';
            break;
          case 2:
            backgroundColor = '#F7F7F7';
            break;
          default:
            backgroundColor = '#FFFFFF';
            break;
        }
      }

      if (!subOption.children) {
        return (
          <div
            key={subOption.name}
            id={subOption.name}
            style={{ background: backgroundColor }}
          >
            <Link to={subOption.url} className={classes.links}>
              <ListItem
                button
                className={classes.itemContainer}
                key={subOption.name}
              >
                <Box flexGrow={1}>
                  <ListItemText
                    style={{ paddingLeft: paddingLeft, color: textColor }}
                    primary={subOption.name}
                  />
                </Box>
              </ListItem>
            </Link>
          </div>
        );
      }
      return (
        <div key={subOption.name} style={{ background: backgroundColor }}>
          <ListItem
            button
            className={classes.itemContainer}
            onClick={() => this.handleClick(subOption.name, level + 1)}
          >
            <Box flexGrow={1}>
              <Link to={subOption.url} className={classes.links}>
                <ListItemText
                  style={{ paddingLeft: paddingLeft, color: textColor }}
                  primary={subOption.name}
                  className={classes.itemContent}
                />
              </Link>
            </Box>
            <Box>
              {state[subOption.name] ? (
                <ExpandLess style={{ color: textColor }} />
              ) : (
                <ExpandMore style={{ color: textColor }} />
              )}
            </Box>
          </ListItem>
          <Collapse
            in={isActive ? !state[subOption.name] : state[subOption.name]}
            timeout="auto"
            unmountOnExit
          >
            {this.handler(subOption.children, level + 1)}
          </Collapse>
        </div>
      );
    });
  }

  render() {
    const { classes, hideDrawer } = this.props;
    const level = 0;

    if (hideDrawer) {
      return null;
    }

    return (
      <div className={classes.list}>
        <Drawer
          variant="persistent"
          anchor="left"
          open
          classes={{ paper: classes.list }}
        >
          <div>
            <List>{this.handler(menuItems.items, level)}</List>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  hideDrawer: width < 991,
});

export default withStyles(styles)(withSizes(mapSizesToProps)(MenuBar));
