import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {saasModernTheme} from 'common/src/theme/saasModern';
import {ThemeProvider} from 'styled-components';

const queryString = require('query-string');

const mapStateToProps = ({affiliate, adSense, paddleAffiliateId}) => {
   return {affiliate, adSense, paddleAffiliateId};
};

const mapDispatchToProps = dispatch => {
   return {
      setAffiliate: value => dispatch({type: 'SET_AFFILIATE', value}),
      setAdSense: value => dispatch({type: 'SET_ADSENSE', value}),
      setPaddleAffiliateId: value => dispatch({type: 'SET_PADDLE_AFFILIATE_ID', value}),
   };
};

class Layout extends React.Component {
   componentDidMount() {
      const {affiliate, setAffiliate, adSense, setAdSense, paddleAffiliateId, setPaddleAffiliateId} = this.props;
      const params = queryString.parse(window.location.search);

      if (params) {
         if (params.ref && affiliate !== params.ref) {
            setAffiliate(params.ref);
         } else if (params.ad && adSense !== params.ad) {
            setAdSense(params.ad);
         } else if (params.p_aid && paddleAffiliateId !== params.p_aid) {
            setPaddleAffiliateId(params.p_aid);
         }
      }
   }

   render() {
      const {children} = this.props;

      return (
      <ThemeProvider theme={saasModernTheme}>
         <main>{children}</main>
      </ThemeProvider>
      );
   }
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(Layout);
