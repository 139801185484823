import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

const Navbar = ({ navbarStyle, button, row, menuWrapper }) => {
  const data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 180) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper
      {...navbarStyle}
      className="saas_navbar saas_navbar_childpage"
    >
      <Container>
        <Box {...row}>
          <Link to="/" className="logo-alt" aria-label="PrintableCal">
            <Image
              critical
              fixed={data.logoImage.childImageSharp.fixed}
              alt=""
            />
          </Link>
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={data.saasModernJson.MENU_ITEMS}
              fromChildPage={true}
              currentClassName={null}
              offset={-70}
              overrideCurrent="Documentation"
            />
            <Link to="/Download" className="navbar_button">
              <Button
                {...button}
                className="main-logo"
                title="Download Now"
                style={{
                  borderRadius: 23,
                  fontSize: '16px'
                }}
              />
              <Button
                {...button}
                className="logo-alt"
                title="Download Now"
                style={{
                  borderRadius: 23,
                  fontSize: '16px'
                }}
              />
            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={data.saasModernJson.MENU_ITEMS}
                fromChildPage={true}
                drawerClose={true}
                currentClassName={null}
                offset={-100}
                overrideCurrent="Documentation"
              />
              <Link to="/Download" className="navbar_drawer_button">
                <Button
                  {...button}
                  title="Download Now"
                  style={{
                    borderRadius: 30,
                    height: 60,
                    fontSize: '24px'
                  }}
                />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    minWidth: '128px',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
