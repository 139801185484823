import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import FooterWrapper from './footer.style';
import Image from 'gatsby-image';
import filter_include_exclude_categories from "common/src/assets/image/whatsNew/filter-include-exclude-categories.png";
import filter_include_exclude_titles from "common/src/assets/image/whatsNew/filter-include-exclude-titles.png";
import agenda_color_item_titles from "common/src/assets/image/whatsNew/agenda-color-item-titles.png";
import item_template_calendar_name from "common/src/assets/image/whatsNew/item-template-calendar-name.png";
import timeslot_10_minute from "common/src/assets/image/whatsNew/timeslot-10-minute.png";

const version = 'Version 2022.01 - February 13, 2022';

const releaseNotes = (
   <ul>
      <li>Added a "More Options..." button to Step 1 of the Create Calendar panel, to make it more apparent that additional options can be accessed.</li>
      <li>Improved the calendar settings user interface on Step 3 of the Create Calendar panel to not require scrolling to see all buttons if the calendar name is short enough to fit.</li>
      <li>Improved processing iCalendar-based calendar sources. Resource values will be shown as locations in the output, if the location value is blank in the calendar data source.</li>
      <li>Corrected the date format of some templates to show the correct date format based on the Windows regional settings.</li>
      <li>Other minor corrections and improvements.</li>
   </ul>
);

const Footer = ({
                   row,
                   col,
                   releaseNotesCol,
                   colOne,
                   colTwo,
                   titleStyle,
                   logoStyle,
                   textStyle,
                }) => {
   const imageData = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "vuesoft.png" }) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

   return (
   <FooterWrapper>
      <Container className="footer_container">
         <Box className="row" {...row}>
            <Box {...colOne}>
               <div style={{ textAlign: 'center' }}>
                  <div style={logoStyle}>
                     <Image
                     fixed={imageData.logoImage.childImageSharp.fixed}
                     alt="VueSoft LLC"
                     />
                  </div>
                  <Text content="St. Louis, Missouri, USA" {...textStyle} />
                  <iframe
                  src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.printablecal.com&width=130&layout=button&action=like&size=large&show_faces=true&share=true&height=65&appId=215921745250207"
                  width="130"
                  height="65"
                  style={{ border: 'none', overflow: 'hidden', marginTop: 30 }}
                  scrolling="no"
                  frameBorder="0"
                  title="Like on Facebook"
                  allow="encrypted-media"
                  />
               </div>
            </Box>
            {/* End of footer logo column */}
            <Box {...colTwo}>
               <Box {...col}>
                  <Heading content="About" {...titleStyle} />
                  <p className="aboutLink">
                     <Link to="/About/About-Us">About VueSoft LLC</Link>
                  </p>
                  <p className="aboutLink">
                     <Link to="/About/Privacy-Policy">Privacy Policy</Link>
                  </p>
                  <p className="aboutLink">
                     <Link to="/About/Cookie-Policy">Cookie Policy</Link>
                  </p>
                  <Heading
                  content="Our Other Products"
                  {...titleStyle}
                  style={{ marginTop: 40 }}
                  />
                  <p className="aboutLink">
                     <a
                     href="https://www.vueminder.com"
                     className="ListItem"
                     target="_blank"
                     rel="noopener noreferrer"
                     >
                        VueMinder
                     </a>
                  </p>
                  <p className="aboutLink">
                     <a
                     href="https://www.csv-to-ics.com"
                     className="ListItem"
                     target="_blank"
                     rel="noopener noreferrer"
                     >
                        CSV-to-ICS Converter
                     </a>
                  </p>
               </Box>
               <Box className="releaseNotesCol" {...releaseNotesCol}>
                  <Heading content="What's New in PrintableCal" {...titleStyle} />
                  <Text content={version} />
                  <Text as="div" content={releaseNotes} />
               </Box>
            </Box>
            {/* End of footer List column */}
         </Box>
      </Container>
      <p
      style={{
         textAlign: 'center',
         fontSize: 12,
         color: '#999',
         padding: 12,
      }}
      >
         Copyright &copy; {new Date().getFullYear()} VueSoft LLC. All rights
         reserved.
      </p>
   </FooterWrapper>
   );
};

// Footer style props
Footer.propTypes = {
   row: PropTypes.object,
   col: PropTypes.object,
   doubleCol: PropTypes.object,
   colOne: PropTypes.object,
   colTwo: PropTypes.object,
   titleStyle: PropTypes.object,
   textStyle: PropTypes.object,
   logoStyle: PropTypes.object,
   copyrightStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
   // Footer row default style
   row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
   },
   // Footer col one style
   colOne: {
      width: [1, '35%', '35%', '23%'],
      mt: 0,
      mb: ['30px', 0],
      pl: ['15px', 0],
      pr: ['15px', '15px', 0],
   },
   // Footer col two style
   colTwo: {
      width: ['100%', '65%', '65%', '77%'],
      flexBox: true,
      flexWrap: 'wrap',
   },
   // Footer col default style
   col: {
      width: ['100%', '50%', '25%', '25%'],
      pl: '15px',
      pr: '15px',
      mb: '10px',
   },
   releaseNotesCol: {
      width: ['100%', '100%', '75%', '75%'],
      pl: '15px',
      pr: '15px',
      mb: '10px',
   },
   // widget title default style
   titleStyle: {
      color: '#343d48',
      fontSize: '16px',
      fontWeight: '700',
      mb: '16px',
   },
   // Default logo size
   logoStyle: {
      width: '130px',
      mb: '15px',
      display: 'inline-block',
   },
   // widget text default style
   textStyle: {
      color: '#0f2137',
      fontSize: '16px',
      mb: '10px',
   },
};

export default Footer;
